import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React from "react"

const Box = styled.div`
  padding: 16px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(16px);
  border-radius: 32px;
  text-align: center;
  max-width: 672px;
  margin: 0 auto;

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(220, 220, 220, 0.9);
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 1rem auto;
  }

  @media (min-width: 768px) {
    p {
      margin: 2rem auto;
    }
  }
`
export default function Insurance() {
  const { bg } = useStaticQuery(graphql`
    query insuranceBg {
      bg: file(relativePath: { eq: "insurance-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 90)
        }
      }
    }
  `)

  let image = getImage(bg)

  return (
    <BgImage
      image={image}
      Tag="section"
      className="container"
      css={css`
        height: 720px;
        padding: 0 9px 32px;
        display: flex;
        align-items: flex-end;

        @media (min-width: 768px) {
          height: 640px;
        }
      `}
    >
      <Box>
        <h3>NO INSURANCE? NO WORRIES</h3>
        <p>
          Are you a patient of our practice without dental insurance? Then join
          our membership club, taking care of your preventive care with a
          monthly subscription, and save money today!
        </p>
      </Box>
    </BgImage>
  )
}
