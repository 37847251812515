import React from "react"
import AboutDr from "../components/AboutDr"
import AboutUs from "../components/AboutUs"
import Insurance from "../components/Insurance"
import Layout from "../components/layout/Layout"
import Seo from "../components/layout/Seo"
import TeamPics from "../components/TeamPics"

const IndexPage = () => (
  <Layout aboutUsPage homepage>
    <Seo />
    <AboutDr />
    <TeamPics />
    <Insurance />
  </Layout>
)

export default IndexPage
