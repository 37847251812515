import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import { Link } from "gatsby"

const Section = styled.section`
  padding: 32px 0;
  text-align: center;
  a {
    :hover {
      //bro give me the blue color from theme
      color: ${props => props.theme.blue};
    }
  }
  h2 {
    margin: 0;
  }

  p {
    font-size: 1.125rem;
    margin: 0 auto 32px;
  }

  @media (min-width: 768px) {
    padding: 64px 0;
  }

  @media (min-width: 1024px) {
    h2 {
      font-size: 4.25rem;
      line-height: 1.62em;
    }
  }
`

const ImageGrid = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, 150px) 1fr;
  grid-template-rows: 144px 101px 150px;
  width: 927px;
  margin: 0 auto;

  div:nth-of-type(3) {
    grid-row: span 2;
  }

  div:nth-of-type(4) {
    grid-row: span 3;
    grid-column: span 2;

    img {
      width: 130%;
    }
  }

  div:nth-of-type(5) {
    grid-row: span 2;
    grid-column: span 2;
  }

  @media (min-width: 768px) {
    width: 1439px;
    gap: 8px;
    grid-template-columns: repeat(4, 233px) 1fr;
    grid-template-rows: 223px 156px 233px;
  }
`

export default function TeamPics() {
  return (
    <Section>
      <Link to="/about-us/our-team/">
        <h2>Our Team</h2>
        <p>Come in and meet our amazing staff!</p>
      </Link>
      <ScrollContainer component="section">
        <ImageGrid>
          <StaticImage
            src="../images/team/morgan.jpeg"
            placeholder="blurred"
            quality={100}
            alt="team member"
          />
          <StaticImage
            src="../images/team/2.jpg"
            placeholder="blurred"
            quality={100}
            alt="team member"
          />
          <StaticImage
            src="../images/team/3.jpg"
            placeholder="blurred"
            quality={100}
            alt="team member"
          />
          <StaticImage
            src="../images/team/4.jpg"
            placeholder="blurred"
            quality={100}
            alt="team member"
          />

          <StaticImage
            src="../images/team/5.jpg"
            placeholder="blurred"
            quality={100}
            alt="team member"
          />
          <StaticImage
            src="../images/team/6.jpg"
            placeholder="blurred"
            quality={100}
            alt="team member"
          />
        </ImageGrid>
      </ScrollContainer>
    </Section>
  )
}
