import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import { BlackButton } from "./layout/ReusableComponents"

const Box = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(50px);
  margin-bottom: 16px;
  max-width: 280px;

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(245, 245, 245, 0.7);
  }

  h3 {
    margin: 0;
    span {
      display: none;
    }
  }

  p:first-of-type {
    font-weight: 700;
  }

  p:last-of-type {
    display: none;
  }

  @media (min-width: 768px) {
    max-width: 552px;
    padding: 32px;
    margin-bottom: 90px;

    h3 {
      font-size: 2.625rem;
      line-height: 1.62em;
      span {
        display: inline;
      }
    }

    p:first-of-type {
      display: none;
    }

    p:last-of-type {
      display: block;
      margin: 32px 0;
    }
  }

  @media (min-width: 768px) and (max-width: 980px) {
    max-width: 400px;
  }
`

const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1032px;
  padding: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(16px);

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(220, 220, 220, 0.9);
  }
`

export default function AboutDr() {
  const { mobileBg, desktopBg } = useStaticQuery(
    graphql`
      query aboutDrBg {
        mobileBg: file(relativePath: { eq: "team/dr-bg-mobile.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 80)
          }
        }
        desktopBg: file(relativePath: { eq: "team/dr-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 80)
          }
        }
      }
    `
  )

  let image = getImage(mobileBg)

  if (typeof window !== "undefined" && window.innerWidth >= 768) {
    image = getImage(desktopBg)
  }

  return (
    <BgImage
      image={image}
      Tag="section"
      css={css`
        height: 700px;
        padding: 16px 0 16px 16px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        @media (min-width: 550px) and (max-width: 767px) {
          ::after {
            background-position: center top -100px !important;
          }
        }

        @media (min-width: 768px) {
          height: 900px;
          margin: 48px;
          padding: 64px 0 64px 64px;
        }
      `}
    >
      <Box>
        <h3>
          <span>About </span>Dr. Theodore Chamberlain
        </h3>
        <p>DMD, FAGD</p>
        <p>
          Raised right here in Clearwater, When Dr. Chamberlain is not
          practicing dentistry, he enjoys fitness and golf.
        </p>
        <BlackButton as={Link} to="/about-us/about-doctor/">
          Learn More About Doctor
        </BlackButton>
      </Box>
      <ScrollContainer
        css={css`
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          max-width: max-content;
        `}
      >
        <Logos>
          <StaticImage
            src="../images/association/1.png"
            placeholder="blurred"
            quality={50}
            alt="american academy of cosmetic dentistry"
          />
          <StaticImage
            src="../images/association/2.png"
            placeholder="blurred"
            quality={50}
            alt="american academy of implant dentistry"
          />
          <StaticImage
            src="../images/association/3.png"
            placeholder="blurred"
            quality={50}
            alt="american academy of dental medicine"
          />
          <StaticImage
            src="../images/association/4.png"
            placeholder="blurred"
            quality={50}
            alt="international congress of oral implantologists fellow"
          />
          <StaticImage
            src="../images/association/5.png"
            placeholder="blurred"
            quality={50}
            alt="incisal edge"
            css={css`
              margin-left: 35px;
            `}
          />
          <StaticImage
            src="../images/association/6.png"
            placeholder="blurred"
            quality={50}
            alt="40 under 40"
          />
        </Logos>
      </ScrollContainer>
    </BgImage>
  )
}
